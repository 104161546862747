<template>
  <div>
    <el-dialog
      title="充值购买更划算"
      :visible.sync="balanceDialogVisible"
      :modal-append-to-body="false"
      @close="closeBalanceDialog"
      class="width-show"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <div class="from-balance-box" v-if="balanceList && balanceList.length" v-loading="payLoading">
        <div class="wallet-title">
          当前余额：
          <span class="color">{{balanceNum}}</span>
          智慧豆
          <span class="color-h5-show">(选择充值金额，多充多送)</span>
        </div>
        <div class="wallet-box">
          <div class="wallet-itme"
               :class="{'active': balanceActive === index}"
               v-for="(item,index) in balanceList"
               @click="selectBalance(item,index)">
            <div class="item_title">充值 <span>{{item.num}}</span> 智慧豆</div>
            <div class="item_text">赠送 <span>{{item.presents}}</span> 智慧豆</div>
            <div class="mark" v-if="index == 2">超值</div>
            <span class="iconfont">
              <i class="el-icon-check"></i>
            </span>
          </div>
        </div>
        <div class="wallet-title h20">
          自定义充值
<!--          <span>(只支持>1的整数)</span>-->
        </div>
        <div class="wallet-input">
          <el-input class="input-item"
                    :class="{'input-focus' : setInput === true }"
                    v-model.number="inputValue"
                    ref="inputRef"
                    @focus="handleFocus"
                    @change="walletInput"
                    maxlength="6">
          </el-input>
          <span class="input-txt">
            智慧豆
            <span>{{ rechargeInfo.num ? rechargeInfo.num : ''}}</span>
          </span>
        </div>
        <div class="pay-cont pay-pc-show">
          <div class="pay-img">
            <div v-if="!payQrCode && orderDetail.qrCode">
              <generateQRCode :url="orderDetail.qrCode"></generateQRCode>
            </div>
            <div class="pay-code-tip" v-else>
              请输入有效金额
            </div>
          </div>
          <div class="pay-text">
            <p>
              总金额：
              <span>
                {{ orderDetail.price }}
                <span>元</span>
              </span>
            </p>
            <div class="pay-tip">
              <img src="@/assets/images/icon/wx-pay2.png" alt="">
              <img src="@/assets/images/icon/zfb-pay2.png" alt="">
              <p>
                使用微信、支付宝扫码支付
                <span>(扫码支付,请勿刷新页面)</span>
              </p>
            </div>
          </div>
        </div>
        <div class="pay-check pay-h5-show">
          <div class="pay-top-btn">
            <div class="pay-top-item" @click="btnPayMethod('1')" v-if="!isWeChatBrowser()">
              <img alt="" src="@/assets/images/icon/zfb-pay2.png">
              <span>支付宝支付</span>
              <el-radio class="pay-radio" v-model="payMethod" label="1">{{ '' }}</el-radio>
            </div>
            <div class="pay-top-item" @click="btnPayMethod('2')">
              <img alt="" src="@/assets/images/icon/wx-pay2.png">
              <span>微信支付</span>
              <el-radio class="pay-radio" v-model="payMethod" label="2">{{ '' }}</el-radio>
            </div>
          </div>
          <div class="pay-check-btn">
            <el-button type="primary" size="small" @click="openPay">确认支付</el-button>
          </div>
        </div>
        <ul class="wallet-lis">
          <li>1、充值余额可用于直接购买内容</li>
          <li>2、充值余额为虚拟币，一经充值，不支持退款；充值后的余额不会过期，但无法提现或转赠他人</li>
          <li>3、充值完成后可能需要等待一会儿才能到账，如一直未到账，请提供你的账号，联系客服</li>
        </ul>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import XydouApi from "@/api/xydou"
import CommonApi from "@/api/common"
import generateQRCode from "@/components/generateQRCode/generateQRCode.vue"

export default {
  components: {
    generateQRCode
  },
  data() {
    return {
      windowWidth: document.body.clientWidth,
      isMobile: false,
      balanceDialogVisible: false,
      balanceList: [],
      userInfo: {
        token: ''
      },
      rechargeInfo: {
        goodsId: '',  //商品ID 类型为 商品充值时必填
        num: '',      //自定义数量 类型为 自定义充值时必填
        type: 1,      //充值类型 1 商品充值 2 自定义充值
      },
      balanceActive: 2,
      balanceType: '',
      // dialogOrderDetail: '',
      balanceNum: 0,
      timerWallet: null,
      payLoading: false,
      orderDetail: {
        id: '',
        price: '0',
        qrCode: ''
      },
      inputValue: '',
      payQrCode: false,
      setInput: false,
      payMethod: '1',
    }
  },
  created() {
    this.isMobile = this.windowWidth < 901;
    // 获取充值商品信息
    // this.getXydouList()
  },
  watch: {
    // 监听页面宽度
    windowWidth(newWidth) {
      this.isMobile = newWidth < 901;
    }
  },
  beforeDestroy() {
    //清除定时器查询
    clearInterval(this.timerWallet)
  },
  methods: {
    walletInput(value){
      if(value == 0){
        this.payQrCode = true
        this.orderDetail = this.$options.data().orderDetail
        this.rechargeInfo.num = value
        clearInterval(this.timerWallet)
        return
      }
      const regex = /^(0|[1-9]\d*)$/
      if (!regex.test(value)) {
        this.inputValue = this.rechargeInfo.num
        this.$message.info('请输入正确的金额')
      } else {
        clearInterval(this.timerWallet)
        this.balanceActive = ''
        if(this.rechargeInfo.goodsId){
          delete this.rechargeInfo.goodsId
        }
        this.rechargeInfo.num = value
        this.rechargeInfo.type = 2
        this.rechargeInfo.orderId = this.orderDetail.id
        this.createXydouOrder()
      }
    },
    handleFocus(){
      // 使输入框获取焦点
      this.setInput = true
    },
    closeBalanceDialog(){
      clearInterval(this.timerWallet)
      this.balanceDialogVisible = false
      this.rechargeInfo = this.$options.data().rechargeInfo
      this.balanceNum = 0
      this.setInput = false
      this.inputValue = ''
    },
    // 获取充值商品
    getXydouList(token,balance){
      let _this = this
      _this.userInfo.token = token
      _this.balanceNum = balance
      XydouApi.getXydouList(token).then(res => {
        // console.log(res.data)
        _this.balanceList = res.data
        _this.rechargeInfo.goodsId = _this.balanceList[2].id
        _this.createXydouOrder()
        _this.balanceDialogVisible = true
      })
    },
    // 选择充值金额
    selectBalance(item,index){
      clearInterval(this.timerWallet)
      this.balanceActive = index
      this.rechargeInfo.goodsId = item.id
      delete this.orderDetail.num
      this.rechargeInfo.type = 1
      this.inputValue = ''
      this.setInput = false
      this.rechargeInfo.orderId = this.orderDetail.id
      this.createXydouOrder()
    },
    // 创建充值订单
    createXydouOrder(){
      let _this = this
      _this.payLoading = true
      let time = new Date().getTime()
      let key = localStorage.getItem('key')
      let ticket = localStorage.getItem('ticket')
      let setOf = ticket + time + ticket + ticket + time + ticket
      let infoMd5 = this.$md5(setOf)
      XydouApi.createXydouOrder(_this.rechargeInfo,_this.userInfo.token,key,time,infoMd5).then(res => {
        // console.log(res.data)
        // _this.dialogOrderDetail = res.data
        _this.orderDetail = res.data
        // let params = {
        //   show: true,
        //   title: '充值智慧豆',
        //   info: _this.dialogOrderDetail
        // }
        // if(_this.balanceType === 'pc'){
        //   _this.$refs.DownloadPC.showDrawer(params)
        // }else if(_this.balanceType === 'h5'){
        //   _this.$refs.DownloadH5.showDrawer(params)
        // }
        _this.payQrCode = false
        _this.payLoading = false
        _this.getTimeFun(_this.orderDetail.id)
      })
    },
    // 充值按钮
    createOrder(type){
      this.balanceType = type
      this.payLoading = true
      this.createXydouOrder()
    },
    // 充值组件回调
    downloadOff(info){
      if(!info.payState){
        clearInterval(this.timerWallet)
        this.payLoading = false
      }
    },
    // 查询订单支付状态
    getQueryPayState(orderId){
      let _this = this
      CommonApi.queryPayState(orderId).then(res => {
        if(res.data){
          _this.$message.success('支付成功')
          // 清除定时器查询
          // clearInterval(this.timerWallet)
          // 关闭弹窗
          setTimeout(()=>{
            // this.payLoading = false
            // this.$refs.DownloadPC.handleClose()
            // 传递 刷新用户信息 的消息
            _this.$emit('userSetInfo',true)
            _this.closeBalanceDialog()
          },1000)
        }
      }).catch(() => {
      })
    },
    //定时器查询订单状态
    getTimeFun(id){
      this.timerWallet = setInterval(() => {
        this.getQueryPayState(id)
      }, 3000);
    },
    // 判断是否是微信浏览器
    isWeChatBrowser() {
      const userAgent = navigator.userAgent.toLowerCase()
      const isWx = /micromessenger/.test(userAgent)
      if (isWx) {
        this.payMethod = '2'
      }
      return isWx
    },
    // 移动端 调取支付
    openPay() {
      let _this = this
      if (this.isWeChatBrowser()) {
        window.location.href = `/prod-admin-api/paper/api/public/pay/${_this.orderDetail.id}`
      } else {
        if (_this.payMethod == 1) {
          // 支付宝支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.orderDetail.id}/1`
        } else {
          // 微信支付
          window.location.href = `/prod-admin-api/paper/api/public/h5/pay/${_this.orderDetail.id}/2`
        }
      }
    },
    btnPayMethod(type){
      this.payMethod = type
    }
  }
}
</script>
<style scoped lang="less">
.width-show{
  /deep/ .el-dialog{
    width: 720px;
    margin-top: 5vh !important;
    border-radius: 15px;
    background: linear-gradient(180deg, #deedff 0%, #fbfdff 100%);
    box-shadow: 0 10px 24px 1px #26262d1a;
    .el-dialog__header{
      border-bottom: 0;
    }
    .el-dialog__title{
      height: 28px;
      font-size: 20px;
      font-weight: 400;
      color: #3f4654;
      line-height: 28px;
    }
  }
}
.from-balance-box{
  .wallet-title{
    position: relative;
    width: 560px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto 15px auto;
    color: #3f4654;
    font-size: 12px;
    padding-left: 12px;
    display: flex;
    align-items: center;
    span{
      font-size: 12px;
      color: #8b9098;
      margin-left: 10px;
    }
    span.color{
      font-size: 16px;
      color: #e31c1c;
      margin-right: 10px;
    }
  }
  .wallet-title::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 14px;
    margin-top: -7px;
    background: #6e83f7;
    border-radius: 4px;
  }
  .wallet-box{
    width: 560px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .wallet-itme{
      position: relative;
      width: 30%;
      height: 116px;
      margin-bottom: 20px;
      background-color: #ffffff;
      box-shadow: 0 0 5px rgba(0, 140, 250, 0.15);
      border-radius: 15px;
      border: 2px solid #ffffff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .item_title{
        margin-bottom: 15px;
        color: #3f4654;
        line-height: 22px;
        font-size: 14px;
        span{
          font-size: 20px;
          color: #ee6360;
        }
      }
      .item_text{
        height: 26px;
        line-height: 26px;
        padding: 0 8px;
        text-align: center;
        font-size: 12px;
        color: #6e83f7;
        background-color: #ececff;
        border-radius: 5px;
      }
      .mark{
        position: absolute;
        top: -8px;
        left: -2px;
        font-size: 12px;
        color: #fff;
        padding: 0 8px;
        line-height: 20px;
        background-color: #6e83f7;
        border-radius: 5px;
      }
      .iconfont{
        display: none;
        position: absolute;
        right: -2px;
        bottom: -2px;
        width: 32px;
        padding: 3px 5px;
        text-align: center;
        background-color: #6e83f7;
        border-radius: 15px 0;
        color: #fff;
        font-size: 12px;
      }
    }
    .active{
      border: 2px solid #6e83f7;
      .iconfont{
        display: block;
      }
    }
  }
  .wallet-input{
    width: 560px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .input-item{
      width: 100px;
    }
    .input-focus{
      /deep/ .el-input__inner{
        border: 2px solid #6e83f7;
      }
    }
    .input-txt{
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #3c3c3c;
      span{
        font-size: 16px;
        color: #fc5151;
      }
    }
  }
  .wallet-lis{
    width: 620px;
    margin: 20px auto 0 auto;
    padding: 15px 30px;
    border-radius: 15px;
    background-color: #fafafa;
    color: #8c8c8c;
    line-height: 20px;
    li{
      font-size: 12px;
      line-height: 22px;
    }
  }
  .pc-btn-show{
    display: block;
    width: 120px;
    margin: 20px auto 0 auto;
  }
  .h5-btn-show{
    display: none;
    width: 120px;
    margin: 20px auto 0 auto;
  }
  .pay-cont{
    width: 560px;
    margin: 20px auto 0 auto;
    padding-top: 20px;
    border-top: 1px solid #e3e3e3;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .pay-img{
      position: relative;
      border-radius: 15px;
      border: none;
      width: 145px;
      height: 145px;
      >div{
        padding: 10px;
        border: 1px solid #e6e6e6;
        text-align: center;
        img{
          width: 124px;
          height: 124px;
          padding: 0;
          margin: 0;
          border: 0;
          background-color: #ffffff;
        }
      }
      >div:before,>div:after{
        content: '';
        position: absolute;
        width: 10px;
        height: 10px;
      }
      >div:before{
        bottom: 0;
        left: 0;
        border-bottom: 2px solid #36b7e6;
        border-left: 2px solid #36b7e6;
      }
      >div:after{
        bottom: 0;
        right: 0;
        border-bottom: 2px solid #36b7e6;
        border-right: 2px solid #36b7e6;
      }
    }
    .pay-img:before,
    .pay-img:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
    }
    .pay-img:before {
      top: 0;
      left: 0;
      border-top: 2px solid #36b7e6;
      border-left: 2px solid #36b7e6;
    }
    .pay-img:after {
      top: 0;
      right: 0;
      border-top: 2px solid #36b7e6;
      border-right: 2px solid #36b7e6;
    }
    .pay-text{
      padding-left: 30px;
      p{
        line-height: 30px;
        >span{
          margin: 0 10px 0 5px;
          font-size: 24px;
          font-weight: bold;
          color: #fc5151;
        }
        i{
          font-size: 14px;
          text-decoration: line-through;
        }
      }
      .pay-tip{
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        img{
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        p{
          line-height: 18px;
          display: flex;
          flex-direction: column;
          span{
            font-size: 14px;
            color: #409EFF;
            font-weight: initial;
          }
        }
      }
    }
    .pay-code-tip{
      height: 100%;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .pay-pc-show{
    //display: none;
  }
  .pay-h5-show{
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .width-show{
    /deep/ .el-dialog{
      width: 90%;
      .el-dialog__header{
        padding: 20px 10px 0 10px;
        .el-dialog__title{
          font-size: 16px;
        }
        .el-dialog__close{
          font-size: 18px;
        }
      }
      .el-dialog__body{
        padding: 10px;
      }
    }
  }
  .from-balance-box{
    .pc-btn-show{
      display: none;
    }
    .h5-btn-show{
      display: block;
    }
    .wallet-title{
      position: relative;
      height: 40px;
      line-height: 20px;
      flex-direction: row;
      align-items: flex-start;
      span{
        margin-left: 0;
      }
      .color-h5-show{
        position: absolute;
        top: 20px;
        left: 10px;
      }
    }
    .wallet-title::before{
      top: 0;
      margin-top: 2px;
    }
    .h20{
      height: 20px;
    }
    .wallet-box{
      width: 100%;
      .wallet-itme{
        width: 48%;
        height: 100px;
        margin-bottom: 10px;
        .item_title{
          margin-bottom: 5px;
          font-size: 12px;
          span{
            font-size: 16px;
            color: #ee6360;
          }
        }
        .item_text{
          font-size: 12px;
        }
      }
    }
    .pay-check {
      width: 100%;
      margin-top: 20px;
      background-color: #ffffff;
      box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 1px solid #e4e5e7;

      .pay-top-btn{
        width: 100%;
        padding: 15px 0;
        border-bottom: 1px solid #e4e5e7;
        display: flex;
        flex-direction: row;
        align-items: center;
        .pay-top-item {
          width: 50%;
          padding: 0 10px;
          border-right: 1px solid #e4e5e7;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          img {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }
          span {
            font-size: 12px;
          }
          .pay-radio {
            margin-top: 4px;
            margin-left: auto;
            /deep/ .el-radio__label{
              display: none;
            }
          }
        }
        .pay-top-item:last-child{border-right: 0;}
      }
      .pay-check-btn{
        padding: 5px 10px;
        text-align: center;
      }
    }
    .pay-pc-show{
      display: none;
    }
    .pay-h5-show{
      display: block;
    }
    .wallet-lis{
      width: 100%;
      padding: 10px 0;
      margin-top: 10px;
      li{
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
}
</style>
