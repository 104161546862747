import request from '@/utils/request'

export default {
  // 创建充值智慧豆的订单
  createXydouOrder(data,token,key,time,md5){
    return request({
      url: '/xydou/create/order',
      method: 'post',
      headers: {
        token: token,
        "ticket-key": key,
        timestamp: time,
        sgin: md5
      },
      data: data
    })
  },
  // 获取充值商品信息
  getXydouList(token){
    return request({
      url: '/xydou/list',
      method: 'get',
      headers: {
        token: token
      }
    })
  }
}
